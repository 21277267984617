import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  // timeout: 20000
});

const excludedRoutes = ["/iframe-withdraw", "/health-check"];

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.status);
    if (error.response && error.response.status === 401) {
      console.log("error.config.url", error.config.url);
      console.log("window.location.origin", window.location.origin);
      const requestUrl = new URL(error.config.url, window.location.origin)
        .pathname;
      if (!excludedRoutes.includes(requestUrl)) {
        window.dispatchEvent(new Event("session-expired"));
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
